import React from "react"
import { Link } from "gatsby"
import Col from "react-bootstrap/Col"
import ImageRound from "../components/imageRound"

const FeaturetteProject = props => (
  <Col lg="4" style={{ textAlign: "center" }}>
    <div className=" mx-auto" style={{ width: "140px" }}>
      <ImageRound filename={props.filename} alt={props.alt} />
    </div>
    <h2 className="mt-2">{props.title}</h2>
    <p style={{ marginRight: ".75rem", marginLeft: ".75rem" }}>{props.text}</p>
    <p>
      <Link className="btn btn-secondary" to={props.link}>
        View Project &raquo;
      </Link>
    </p>
  </Col>
)

export default FeaturetteProject

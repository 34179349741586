import React from "react"

import "./style.css"

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Carousel from "react-bootstrap/Carousel"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import Featurette from "../components/featurette"
import FeaturetteProject from "../components/featuretteProject"

const IndexPage = () => (
  <Layout>
    <SEO title="Luk Vermeulen" />
    <Container className="marketing mt-3">
      <Carousel className="">
        <Carousel.Item>
          <Image filename="B3/work/carousel_00.jpg" alt="Urban Planning" />
        </Carousel.Item>
        <Carousel.Item>
          <Image
            filename="B2/work/carousel_00.jpg"
            alt="Interior visualization highrise-building"
          />
        </Carousel.Item>
        <Carousel.Item>
          <Image filename="metal/carousel_00.jpg" alt="Forged knife" />
        </Carousel.Item>
      </Carousel>
      {/* I don't know why, but with those classname that div gets put into the carousel as fixed title */}
      <div className="carousel">
        <div className="d-none d-lg-block text-justify overlay">
          <div className="col-12 mb-5 mt-2">
            <h1>Welcome!</h1>
            <p className="lead">
              My Name is Luk Vermeulen. I am an architecture and technology
              enthusiast. And I love working with Metal!
            </p>
          </div>
        </div>
      </div>
      {/* Title for mobile should not overlay the carousel */}
      <div className="bg-light">
        <div className="d-block d-lg-none text-justify">
          <h1>Welcome!</h1>
          <p className="lead">
            My Name is Luk Vermeulen. I am an architecture and technology
            enthusiast. And I love working with Metal!
          </p>
        </div>
      </div>

      <Row className="mt-4">
        <FeaturetteProject
          className=""
          filename="Bachelorthesis/teaser.jpg"
          alt="segmenting and labeling pointclouds in VR"
          title="Point clouds in VR"
          text="How could editing of point clouds be more intuitive and accessible? I programmed a prototype and wrote my bachelorthesis about segmenting and labeling of point clouds in virtual reality."
          link="projects/segmenting-and-labeling-pointclouds-in-vr"
        />
        <FeaturetteProject
          filename="B3/00_Teaser_Uebersicht_500.jpg"
          alt="urban planning project"
          title="B3 Aachen Südpark"
          text="For the redevelopment plan of this area in south Aachen, a programmatic and flexible green space framed by different housing typologies and a new university campus is proposed."
          link="projects/suedpark-urban-planning"
        />
        <FeaturetteProject
          filename="building-a-knife/00_Teaser.jpg"
          alt="forged knife"
          title="Building a knife"
          text="Take a look at a recent knife project and its creation. Starting with a selected piece of steel and a piece of wood, a blade is forged and assembled with a handle to form a finished knife."
          link="projects/building-a-knife"
        />
      </Row>

      <hr className="featurette-divider" />
      <Featurette
        side="right"
        h2="Architecture"
        text="Combining my passion for technology and design, I find architecture a very interesting field. Finding solutions that balance functionality and beauty that matter to humans in their daily lives is a very exciting challenge."
        link="/work"
        filter="Architecture"
        filename="B2/00_Teaser_Innen_500.jpg"
        alt="Architectural design"
      />
      <hr className="featurette-divider" />
      <Featurette
        side="left"
        h2="Visualization"
        text="From architecture visualization over drawing to digital art, I love experimenting with different styles and techniques. Whether on paper, digital or in virtual reality, it is always about creating beautiful images that tell a story."
        link="/work"
        filter="Visualization"
        filename="B3/00_Teaser_Uebersicht_500.jpg"
        alt="Architecture visualization"
      />
      <hr className="featurette-divider" />
      <Featurette
        side="right"
        h2="Craft"
        text="Building things with my hands has really grown on me since I was a small child. I still remember building a steam machine with my dad when I was little, what a joy! I later started blacksmithing, completing small projects at my forge in the garden. Nowadays, I mostly forge knives."
        link="/work"
        filter="Craft"
        filename="building-a-knife/00_Teaser.jpg"
        alt="Blacksmithing and knifemaking"
      />
      <hr className="featurette-divider" />
      <Featurette
        side="left"
        h2="Research"
        text="Sometimes the tools at hand do not solve our current problems. I love analyzing situations and coming up with new strategies, solutions and new tools, especially when I can program them myself when I need them."
        link="/work"
        filter="Research"
        filename="Bachelorthesis/teaser.jpg"
        alt="Segmenting and labeling point clouds in vr"
      />
    </Container>
  </Layout>
)

export default IndexPage

import React from "react"
import { Link } from "gatsby"

import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

import ImageSquare from "../components/imageSquare"

// Place inside Container
const Featurette = props => {
  if (props.side === "right") {
    return (
      <Row as="div" className="featurette">
        <Col as="div" md="7">
          <h2 className="featurette-heading">{props.h2}</h2>
          <p className="lead">{props.text}</p>
        </Col>
        <Col as="div" md="5">
          <Link to={props.link} state={{ projectFilter: props.filter }}>
            <ImageSquare filename={props.filename} alt={props.alt} />
          </Link>
        </Col>
      </Row>
    )
  } else {
    return (
      <Row as="div" className="featurette">
        <Col as="div" xs={{ span: 12, order: 1 }} md={{ span: 5, order: 0 }}>
          <Link to={props.link} state={{ projectFilter: props.filter }}>
            <ImageSquare filename={props.filename} alt={props.alt} />
          </Link>
        </Col>
        <Col as="div" xs={{ span: 12, order: 0 }} md={{ span: 7, order: 1 }}>
          <h2 className="featurette-heading">{props.h2}</h2>
          <p className="lead">{props.text}</p>
        </Col>
      </Row>
    )
  }
}

export default Featurette
